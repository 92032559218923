import GatsbyImage from "gatsby-image";
import { get } from "lodash";
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Layout/Footer";
import Navbar from "../components/Layout/Navbar";
import TopBar from "../components/Layout/TopBar";
import Seo from "../components/seo";
import SEO from "../components/seo";
import { css } from "@emotion/core";
import tw from "tailwind.macro";
import QuoteMark from "../images/quote-mark.png";
import useIntersectionObserver from "../loaders/useIntersectionObserver";
import shuffle from "lodash/shuffle";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import ReusableTiles from "../components/ReusableTiles";
import SecondReusableTiles from "../components/SecondReusableTiles";
import FeedingGreatness2024 from "../components/FeedingGreatness2024";
import DogStory from "../components/DogStory";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import Navigation from "../components/Layout/Navigation";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
import { graphql } from "gatsby";
import ShareYourStoryButton from "../components/ShareYourStoryButton";
import { InView } from "react-intersection-observer";
export default function Story({ data }) {
  const [show, setShow] = useState(5);
  const endRef = React.useRef();

  const stories = React.useMemo(() => shuffle(data.stories.nodes), []);
  const [cta, setCta] = useState(false);

  const showCta = () => {
    setCta(true);
    // if (typeof window !== "undefined" && window.pageYOffset > 500) {
    //   setCta(true);
    // }
  };
  const hideCta = () => {
    setCta(false);
  };

  return (
    <>
    {/* <SEO title="All dogs" keywords={[`great`, `british`, `dogs`]} /> */}
    <SEO
      title="greatbritishdogs.co.uk | All dogs"
      keywords={[`great`, `british`, `dogs`]}
    />
      <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />
      <AnimateSharedLayout>
        {" "}
        <motion.div layout>
          <AnimatePresence initial={false}>
            {stories &&
              stories.slice(0, show).map((node, index) => (
                <>
                  {(index + 1) % 8 == 0 && (
                    <motion.div
                      layout
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 1 }}
                      className="bg-pale-100"
                    >
                      <Trigger onIntersect={hideCta} onLeave={showCta} />
                      <ReusableTiles />{" "}
                    </motion.div>
                  )}

                  {/* every 8 stories show this -- offset it by 4 places */}
                  {(index + 1 + 4) % 8 == 0 && (
                    <motion.div
                      layout
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 1 }}
                    >
                      <Trigger onIntersect={hideCta} onLeave={showCta} />
                      <FeedingGreatness2024 />
                    </motion.div>
                  )}

                  <DogStory key={node.frontmatter.slug} story={node} />
                </>
              ))}
          </AnimatePresence>
        </motion.div>
      </AnimateSharedLayout>
      <ShareYourStoryButton show={cta} />
      <Trigger onIntersect={hideCta} onLeave={showCta} />

      <InView
        rootMargin="300px 0px 300px 0px"
        as="div"
        onChange={(inView, entry) => {
          if (inView) {
            setShow(show + 5);
          }
        }}
      ></InView>
      <Footer></Footer>
    </>
  );
}

const Trigger = ({ onIntersect, onLeave }) => {
  const ref = useRef();
  useIntersectionObserver({
    target: ref,
    rootMargin: "100px 0px 100px 0px",
    threshold: 0,
    onIntersect,
    onLeave,
  });

  return <div ref={ref}></div>;
};
export const query = graphql`
  {
    stories: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/src/storys/" } }
      sort: { fields: frontmatter___title }
    ) {
      nodes {
        frontmatter {
          title
          tags
          slug
          thumb {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        html
      }
    }
  }
`;
